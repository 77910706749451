







































































































import Vue from "vue";
import AppSiteHero from "@/components/layout/site-hero/AppSiteHero.vue";
import WebinarPromotionCard from "@/components/webinars/WebinarPromotionCard.vue";
import {
  ApiException,
  WebinarAvailability,
  WebinarsClient,
  WebinarsSummariesPublicResponse,
  WebinarsSummaryPublicResponse,
} from "@/clients/clients";
import { mapGetters } from "vuex";
import { RawLocation } from "vue-router";
import AppSiteHeroFooter from "@/components/layout/site-hero/AppSiteHeroFooter.vue";
import AppPage from "@/components/layout/page/AppPage.vue";
import { HealthCareInterests } from "@/providers/HealthCareInterests";

export default Vue.extend({
  name: "WebinarCatalogueView",
  metaInfo: {
    title: "Webinars",
  },
  components: {
    AppPage,
    AppSiteHeroFooter,
    WebinarPromotionCard,
    AppSiteHero,
  },
  data: () => ({
    loading: false,
    topics: [] as string[],
    availability: null as WebinarAvailability | null,
    webinars: [] as WebinarsSummaryPublicResponse[],
    total: 0,
    page: 1,
    pages: 0,
    offset: 0,
    limit: 10,
    errorMessage: "",
  }),
  computed: {
    ...mapGetters(["appLoading", "assetBaseUrl"]),
    webinarAvailability(): typeof WebinarAvailability {
      return WebinarAvailability;
    },
    webinarTopics(): typeof HealthCareInterests {
      return HealthCareInterests;
    },
  },
  async created() {
    this.loading = true;
    this.errorMessage = "";
    this.topics = this.getTopicsFromQueryString();
    this.availability = this.getAvailabilityFromQueryString();
    try {
      const response = await this.getWebinars();
      this.total = response.total ?? 0;
      this.pages = Math.ceil(this.total / this.limit);
      this.webinars = response.webinars ?? [];
    } catch (e) {
      this.errorMessage =
        (e as ApiException).message ?? "Unknown error occurred";
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getTopicsFromQueryString(): string[] {
      if (this.$router.currentRoute.query["topic"]) {
        return Array.isArray(this.$router.currentRoute.query["topic"])
          ? ([
              ...this.$router.currentRoute.query["topic"].filter(
                (p) => p?.length
              ),
            ] as string[])
          : [this.$router.currentRoute.query["topic"] ?? ""];
      }
      return [];
    },
    getAvailabilityFromQueryString(): WebinarAvailability | null {
      if (this.$router.currentRoute.query["availability"]) {
        const availability = Array.isArray(
          this.$router.currentRoute.query["availability"]
        )
          ? this.$router.currentRoute.query["availability"][0] ?? ""
          : this.$router.currentRoute.query["availability"] ?? "";
        if (!availability.length) return null;

        return WebinarAvailability[
          availability as keyof typeof WebinarAvailability
        ];
      }
      return null;
    },
    async updateRouteQueries(): Promise<void> {
      const route: RawLocation = { name: "Webinars" };
      if (this.topics.length) {
        route.query = Object.assign({}, { topic: this.topics }, route.query);
      }
      if (this.availability) {
        const availability = encodeURIComponent(this.availability);
        route.query = Object.assign(
          {},
          { availability: availability },
          route.query
        );
      }
      await this.$router.push(route);
    },
    async getWebinars(): Promise<WebinarsSummariesPublicResponse> {
      const client = this.$httpClientFactory(WebinarsClient);
      return await client.webinars(
        this.topics,
        this.availability ?? undefined,
        undefined,
        this.offset,
        this.limit
      );
    },
  },
  watch: {
    "$route.query": async function () {
      this.loading = true;
      this.errorMessage = "";
      this.topics = this.getTopicsFromQueryString();
      this.availability = this.getAvailabilityFromQueryString();
      try {
        const response = await this.getWebinars();
        this.total = response.total ?? 0;
        this.pages = Math.ceil(this.total / this.limit);
        this.webinars = response.webinars ?? [];
      } catch (e) {
        this.errorMessage =
          (e as ApiException).message ?? "Unknown error occurred";
      } finally {
        this.loading = false;
      }
    },
  },
});
